








































import { Vue, Component } from 'vue-property-decorator'
import { api } from '../services/api.service'
import TotalScore from '@/components/TotalScore.vue'

type Rank = {
  name: string;
  score: number;
}

@Component({
  components: {
    TotalScore
  }
})
export default class LeaderboardView extends Vue {
  ranking: { companies: Rank[]; branches: Rank[] } = {
    companies: [],
    branches: []
  }

  score = 0

  position = 1

  isLeaderboardReady = false

  currentTab: 'companies' | 'branches' = 'companies'

  mounted () {
    this.setBackgroundVideo()
    this.fetchLeaderboard()
  }

  get currentRanking (): Rank[] {
    return this.ranking[this.currentTab]
  }

  setBackgroundVideo () {
    const bgVideo = 'https://sanquinquizatwork.s3.amazonaws.com/quiz/question.mp4'

    this.$store.commit('setBackgroundVideo', bgVideo)
  }

  async fetchLeaderboard () {
    try {
      const { data } = await api.request('/leaderboard')

      const { companies, branches, score, position } = data

      this.ranking.companies = companies.filter((company: any) => {
        // hide
        return !(['Crowdster', 'Sanquin', 'Gmail', 'gmail'].includes(company.name))
      })
      this.ranking.branches = branches
      this.score = score
      this.position = position
      this.isLeaderboardReady = true
    } catch (error) {
      console.error(error)
    }
  }
}
